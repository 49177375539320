import React, { ReactElement, useEffect } from 'react';
import classnames from 'classnames';
import analyticsTrack from '@lib/analytics/analyticsTrack';
import useHolidaySale from '@hooks/useHolidaySale/useHolidaySale';
import { getResizedImageUrl } from '@lib/image';
import { PACKAGE_DETAILS, PACKAGE_NAMES } from './common';
import PackageCard from './PackageCard/PackageCard';

import styles from './PricingCards.module.scss';

interface IPricingCardsPropsV2 {
  CTA: (props: any) => void;
  className?: string;
  cards?: ReactElement;
}

export default function PricingCardsV2({
  CTA,
  className = '',
  cards
}: IPricingCardsPropsV2) {
  const holidaySale = useHolidaySale();

  useEffect(() => {
    analyticsTrack('package_cards_viewed');
  }, []);

  const packageImageMobile = getResizedImageUrl({
    height: 473,
    width: 473,
    fit: 'fit-in',
    url: 'https://static.havenly.com/home/package-bg-m.png',
    quality: 80,
    valign: 'middle',
    halign: 'center'
  });

  return (
    <div className={styles.Root}>
      {/* eslint-disable-next-line max-len */}
      <div className={styles.sectionDesc}>Our interior design services are tailored to your space, style, and&nbsp;budget.</div>
      <div className={classnames(styles.CardsWrapper, className)} id="package-types">
        {cards ? (
          <>{cards}</>
        ) : (
          <>
            <PackageCard
              CTA={CTA}
              type={PACKAGE_NAMES.FULL}
              title={PACKAGE_DETAILS[PACKAGE_NAMES.FULL].title}
              useCase={PACKAGE_DETAILS[PACKAGE_NAMES.FULL].description}
              holidaySale={holidaySale}
            />

            <PackageCard
              CTA={CTA}
              type={PACKAGE_NAMES.AT_HOME}
              title={PACKAGE_DETAILS[PACKAGE_NAMES.AT_HOME].title}
              useCase={PACKAGE_DETAILS[PACKAGE_NAMES.AT_HOME].description}
              showLocations
              locations={PACKAGE_DETAILS[PACKAGE_NAMES.AT_HOME].locations}
              holidaySale={holidaySale}
            />
          </>
        )}

        <div className={styles.PackageImage}>
          <picture>
            <source
              srcSet={getResponsiveImages(
                'https://static.havenly.com/home/package-bg.png',
                866,
                572,
                'png'
              )}
              media={'(min-width: 1200px)'}
            />
            <source
              srcSet={getResponsiveImages(
                'https://static.havenly.com/home/package-bg.png',
                710,
                469,
                'png'
              )}
              media={'(min-width: 768px)'}
            />
            <img src={packageImageMobile} alt="Havenly Packages" />
          </picture>
        </div>
      </div>
    </div>
  );
}

function getResponsiveImages(
  image: string | undefined,
  width: number,
  height: number,
  format: string
) {
  if (!image) return '';
  const main = [`${getThumborURL(image, width, height, format)} ${width}w`];
  main.push(`${getThumborURL(image, width * 2, height * 2, format)} 2x`);

  return main.join(', ');
}

function getThumborURL(
  url: string | undefined,
  width: number,
  height: number,
  format: string
): string {
  if (!url) return '';
  return getResizedImageUrl({
    format,
    quality: 90,
    url,
    width,
    height,
  });
}
