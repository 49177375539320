import { useState } from 'react';

export default function useToggle(
  initialState: boolean = true
): [boolean, (enabled?: boolean) => void] {
  const [enabled, setEnabled] = useState<boolean>(initialState);
  const toggleState = (isEnabled?: boolean) => {
    if (typeof isEnabled === 'undefined') {
      setEnabled(!enabled);
    } else {
      setEnabled(isEnabled);
    }
  };

  return [enabled, toggleState];
}
