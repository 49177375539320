import HolidaySale from '@hooks/useHolidaySale/HolidaySale';
import { DateTime } from 'luxon';
import { useRouter } from 'next/router';
import { useQuery } from '@apollo/client';
import { useMemo, useState } from 'react';
import { createHolidaySale, HOLIDAY_SALE_QUERY } from './holiday-sale.graphql';

export default function useHolidaySale(saleDate?: string) {
  const router = useRouter();
  const dateParam = saleDate || router.query.__testSale__ as string;

  // Neeed `useMemo` to avoid and endless requery loop. (DateTime.now() is always changing)
  const currentDate = useMemo(
    () => (dateParam ? DateTime.fromISO(dateParam) : DateTime.now()), [dateParam]
  );

  const [holidaySale, setHolidaySale] = useState<HolidaySale>(new HolidaySale(currentDate));

  useQuery(HOLIDAY_SALE_QUERY, {
    variables: {
      forDate: currentDate
    },
    onCompleted: (data) => {
      if (data?.currentPromotions?.length > 0) {
        const promo = data.currentPromotions[0];
        const sale = createHolidaySale({
          data: promo,
          currentDate: currentDate.toISO()
        });
        setHolidaySale(sale);
      }
    }
  });

  return holidaySale;
}
