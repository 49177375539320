import React, { ReactElement } from 'react';
import classNames from 'classnames';
import PricingCardsV2 from '@components/shared/PrincingCardsV2/PricingCardsV2';
import { PACKAGES } from '@components/shared/PrincingCardsV2/common';
import { useRouter } from 'next/router';
import useGetStartedCtaLink from '@hooks/useGetStartedCtaLink/useGetStartedCtaLink';
import styles from './Packages.module.scss';

interface IPackages {
  className?: string;
  merch?: boolean;
  cards?: ReactElement;
  cardsClassName?: string;
}

const Packages = ({
  className = '',
  cardsClassName = '',
  merch,
  cards,
}: IPackages) => {
  const router = useRouter();
  const link = useGetStartedCtaLink();

  const handleCTA = (pkg: PACKAGES) => {
    if (pkg === PACKAGES.AT_HOME) {
      return router.push('/in-person');
    }
    if (merch) {
      let giftQuery = 'full';
      if (pkg === PACKAGES.MINI) {
        giftQuery = 'mini';
      } else if (pkg === PACKAGES.MERCH) {
        giftQuery = 'merch';
      }
      return router.push(`/gift/payment?gift=${giftQuery}`);
    }
    return router.push(link);
  };

  return (
    <section className={classNames(
      styles.Container,
      className,
      styles.isNewHomePage
    )}
    >
      <PricingCardsV2 CTA={handleCTA} cards={cards} className={cardsClassName} />
    </section>
  );
};

export default Packages;
