import React from 'react';
import { useTheme } from '@components/theme';

import { DiscountType, RoomPackageDiscount } from '@hooks/useHolidaySale/HolidaySale';
import classNames from 'classnames';
import styles from './PromoSaveStamp.module.scss';

interface IPromoSaveStampProps {
  className?: string;
  packageDiscount?: RoomPackageDiscount;
}
export default function PromoSaveStamp({
  className,
  packageDiscount
}: IPromoSaveStampProps) {
  const theme = useTheme();

  return (
    packageDiscount && packageDiscount.discountType === DiscountType.PERCENT ? (
      <div
        className={classNames(
          theme.styles.Body,
          styles.Root,
          className
        )}
      >
        <div className={styles.HalfCircle} />
        <div className={styles.StampWrapper}>
          <span className={classNames(styles.Percent)}>
            {`${packageDiscount.discount}%`}
          </span>
          <span className={styles.Off}>off</span>
        </div>
      </div>
    ) : null
  );
}
