import gql from 'graphql-tag';
import HolidaySale, { RoomPackageDiscount } from '@hooks/useHolidaySale/HolidaySale';
import { DateTime, Interval } from 'luxon';

export const HOLIDAY_SALE_QUERY = gql`
  query HolidaySale($forDate: DateTime) {
    currentPromotions(forDate: $forDate) {
      id
      name
      displayName
      emoji
      startTime
      endTime
      endDate
      endDateText
      packageDiscounts {
        id
        addOnPrice
        packageId
        discountText
        msrp
        salePrice
        discount
        discountType
        code {
          id
          code
          discount
          discountType
        }

      }
    }
  }
`;

export interface IHolidaySaleData {
  data?: any;
  currentDate: string;
}

export function createHolidaySale(saleData?: IHolidaySaleData): HolidaySale {
  if (saleData?.data) {
    const {
      data: promo,
      currentDate
    } = saleData;
    return new HolidaySale(DateTime.fromISO(currentDate), {
      name: promo.displayName,
      emoji: promo.emoji,
      saleInterval: Interval.fromDateTimes(
        DateTime.fromISO(promo.startTime),
        DateTime.fromISO(promo.endTime).plus({ seconds: 1 })
      ),
      saleEndDisplay: DateTime.fromISO(promo.endDate, { zone: 'America/Denver' }),
      saleEndText: promo.endDateText,
      packageDiscounts: promo.packageDiscounts.map((discount: any) => {
        return new RoomPackageDiscount({
          packageId: discount.packageId,
          code: discount.code.code,
          salePrice: discount.salePrice,
          msrp: discount.msrp,
          discountText: discount.discountText,
          discount: discount.discount,
          discountTypeId: discount.discountType,
          addOnPrice: discount.addOnPrice
        });
      })
    });
  }

  return new HolidaySale(DateTime.now());
}
