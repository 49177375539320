/* eslint-disable max-classes-per-file */
import { roomPackagesByName } from '@constants/roomPackages';
import { DateTime, Interval } from 'luxon';

export enum DiscountType {
  DOLLAR = '1', PERCENT = '2'
}

export class RoomPackageDiscount {
  private readonly _code: string;

  private readonly _discountText: string;

  private readonly _packageId: number;

  private readonly _msrp: number;

  private readonly _salePrice: number;

  private readonly _discount: number;

  private readonly _addOnPrice: number;

  private readonly _discountType: DiscountType;

  public constructor(
    {
      packageId,
      code,
      discountText,
      msrp,
      salePrice,
      discount,
      discountTypeId,
      addOnPrice
    }: {
      packageId: number;
      code: string;
      discountText: string;
      msrp: number;
      salePrice: number;
      discount: number;
      discountTypeId: string;
      addOnPrice: number;
    }
  ) {
    this._packageId = packageId;
    this._code = code;
    this._discountText = discountText;
    this._msrp = msrp;
    this._salePrice = salePrice;
    this._discount = discount;
    this._discountType = discountTypeId as DiscountType;
    this._addOnPrice = addOnPrice;
  }

  public get packageId() {
    return this._packageId;
  }

  public get code() {
    return this._code;
  }

  public get discountText() {
    return this._discountText;
  }

  get msrp(): number {
    return this._msrp;
  }

  get salePrice(): number {
    return this._salePrice;
  }

  get discount(): number {
    return this._discount;
  }

  get discountType(): DiscountType {
    return this._discountType;
  }

  get addOnPrice(): number {
    return this._addOnPrice;
  }
}

interface IHolidaySaleProps {
  name: string;
  emoji: string;
  saleInterval: Interval;
  saleEndDisplay: DateTime;
  saleEndText?: string;
  packageDiscounts: RoomPackageDiscount[];
}

export default class HolidaySale {
  private readonly saleInterval?:Interval;

  private readonly saleEndDisplay?:DateTime; // "through 7/6"

  private readonly _saleEndText?: string;

  private readonly _name?:string;

  private readonly _emoji?:string;

  private readonly currentDate: DateTime;

  private readonly _packageDiscounts = new Map<number, RoomPackageDiscount>();

  public constructor(currentDate:DateTime, saleProps?:IHolidaySaleProps) {
    if (saleProps) {
      const {
        emoji,
        name,
        packageDiscounts,
        saleEndDisplay,
        saleEndText,
        saleInterval
      } = saleProps;

      this.saleInterval = saleInterval;
      this.saleEndDisplay = saleEndDisplay;
      this._emoji = emoji;
      this._name = name;
      this._saleEndText = saleEndText;

      packageDiscounts
        .forEach((discount) => {
          this._packageDiscounts.set(Number(discount.packageId), discount);
        });
    }
    this.currentDate = currentDate;
  }

  public isActive() {
    return !!this.saleInterval && this.saleInterval.contains(this.currentDate);
  }

  public get end() {
    return this.saleInterval?.end;
  }

  public get saleEndText() {
    return this._saleEndText || `Through ${this.formattedEnd}`;
  }

  public get formattedEnd() {
    const saleEnd = this.saleEndDisplay || this.end;
    return saleEnd?.setZone('America/Denver').toFormat('cccc L/d');
  }

  public get start() {
    return this.saleInterval?.start;
  }

  public get name() {
    return this._name;
  }

  public get emoji() {
    return this._emoji;
  }

  public roomPackageDiscount(roomPackage: number | string): RoomPackageDiscount | undefined {
    let roomPackageId: number;
    if (typeof roomPackage === 'string') {
      roomPackageId = Number(roomPackagesByName[roomPackage]);
    } else {
      roomPackageId = roomPackage;
    }
    if (roomPackageId) {
      return this._packageDiscounts.get(roomPackageId);
    }

    return undefined;
  }
}
